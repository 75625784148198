import { Box, TextField, IconButton } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AddEntityID } from "../../store/types";


const AddEntitiesIDs = ({
  entityName,
  entityData,
  callbackEntityData,
}: {
  entityName: string;
  entityData: AddEntityID[];
  callbackEntityData: (data: AddEntityID[]) => void;
}) => {
  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    const newEntityData: AddEntityID[] = [...entityData];
    newEntityData[index][name as keyof AddEntityID] = value;
    newEntityData[index].nameSpace = entityName;
    callbackEntityData(newEntityData);
  };

  const handleAddField = () => {
    const addedEntityData = [
      ...entityData,
      {
        entityId: "",
        nameSpace: "",
      },
    ];
    callbackEntityData(addedEntityData);
  };
  const handleRemoveField = (index: number) => {
    const array = [...entityData];
    if (index > 0 && index < array.length) {
      array.splice(index, 1);
      callbackEntityData(array);
    }
  };

  return (
    <Box>
      {entityData.map((singleEntityData, index) => (
        <Box
          key={`${index}-entity-${singleEntityData.nameSpace}`}
          display={"flex"}
        >
          <TextField
            id={`text-area-entity-id-${index + 1}`}
            autoFocus
            autoComplete="off"
            margin="dense"
            name="entityId"
            label={`ID #${index + 1}`}
            type="text"
            fullWidth
            variant="standard"
            value={singleEntityData.entityId}
            onChange={(e) => handleChange(index, e)}
          />
          {index !== 0 && (
            <IconButton
              onClick={() => handleRemoveField(index)}
              sx={{ height: "40px", alignSelf: "flex-end" }}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <IconButton onClick={handleAddField}>
        <AddCircleOutlineIcon />
      </IconButton>
    </Box>
  );
};
export default AddEntitiesIDs;
