import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, AlertColor, Box, Snackbar } from "@mui/material";

type DialogMessage = {
  message: string | null;
  messageType: AlertColor | undefined;
};

interface SnackBarMessageProps {
  dialogMessage: DialogMessage | null;
  resetAction?: () => void;
}

const SnackBarMessage = ({ dialogMessage,resetAction }: SnackBarMessageProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (dialogMessage?.message) {
      setOpen(true);
    }
  }, [dialogMessage]);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    resetAction?.()
  };

  return (
    <Box sx={{ flex: 1 }}>
      {dialogMessage?.message && dialogMessage?.messageType && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={dialogMessage.messageType}
            sx={{ width: "100%" }}
          >
            {dialogMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default SnackBarMessage;
