export const calculationResponseMock = {
  entities: {
    human: {
      John: {
        id: "John",
        ns: "human",
      },
      Isaac: {
        id: "Isaac",
        ns: "human",
      },
      Anne: {
        id: "Anne",
        ns: "human",
      },
      Peter: {
        id: "Peter",
        ns: "human",
      },
    },
    pets: {
      cat: {
        id: "cat",
        ns: "pets",
      },
      dog: {
        id: "dog",
        ns: "pets",
      },
      cow: {
        id: "cow",
        ns: "pets",
      },
    },
  },
  relations: {
    father_of: [
      ["John", "Isaac"],
      ["Isaac", "Anne"],
      ["Anne", "Peter"],
    ],
    ancestor_of: [
      ["John", "Isaac"],
      ["John", "Anne"],
      ["Isaac", "Anne"],
      ["John", "Peter"],
      ["Isaac", "Peter"],
      ["Anne", "Peter"],
    ],
  },
};

export const mockRules = [
  "father_of(a,c) => ancestor_of(a,c)",
  "ancestor_of(a,b) and father_of(b,c) => ancestor_of(a,c)",
  "owns(a,b) => has_pet(a,b)",
];

export const mockEvents = [
  "update human John",
  "update human Isaac",
  "update human Anne",
  "update human Peter",
  "update pets cat",
  "update pets dog",
  "update pets cow",
  "link father_of John Isaac",
  "link father_of Isaac Anne",
  "link father_of Anne Peter",
  "link ancestor John Isaac",
  "link ancestor Isaac Anne",
  "link ancestor Anne Peter",
  "link owns cat John",
  "link owns dog Isaac",
  "link owns cow Anne",
];

//======== ancestory ==========
export const ancestoryMockRules = [
  "father_of(a,c) => ancestor_of(a,c)",
  "ancestor_of(a,b) and father_of(b,c) => ancestor_of(a,c)",
];

export const ancestoryMockEvents = [
  "update human grand_grandfather",
  "update human grandfather",
  "update human father",
  "update human son",
  "link father_of grand_grandfather grandfather",
  "link father_of grandfather father",
  "link father_of father son",
];

//======== friend recomendation ==========
export const friendMockRules = [
  "friend_of(a,b) => friend_of(b,a)",
  "friend_of(a,c) and friend_of(b,c) and distinct(a,b) => have_common_friend(a,b)",
  "user_in_group(a,g) and user_in_group(b,g) and have_common_friend(a,b) and not friend_of(a,b) => recomend_friend(a,b)",
];

export const friendMockEvents = [
  "update user u1",
  "update user u2",
  "update user u3",
  "link friend_of u1 u3",
  "link friend_of u2 u3",
  "link user_in_group u1 g1",
  "link user_in_group u2 g1",
];

//======== genes inheritance ==========
export const genesMockRules = [
  "genes_of(a,b) => genes_of(b,a)",
  "genes_of(a,c) and genes_of(b,c) and distinct(a,b) => has_common_genes(a,b)",
  "human_with_genes(a,g) and human_with_genes(b,g) and has_common_genes(a,b) and not genes_of(a,b) => has_genes(a,b)",
];

export const genesMockEvents = [
  "update human h1",
  "update human h2",
  "update human h3",
  "link genes_of h1 h3",
  "link genes_of h2 h3",
  "link human_with_genes h1 g1",
  "link human_with_genes h2 g1",
];

export const inputRelationsTableRowsMock = [
  {
    id: "father_of-1",
    index: 1,
    relationName: "father_of",
    relationMembers: ["John", "Isaac"],
    membersNs: [
      { id: "John", ns: "human" },
      { id: "Isaac", ns: "human" },
    ],
  },
  {
    id: "father_of-2",
    index: 2,
    relationName: "father_of",
    relationMembers: ["Isaac", "Anne"],
    membersNs: [
      { id: "Isaac", ns: "human" },
      { id: "Anne", ns: "human" },
    ],
  },
  {
    id: "father_of-3",
    index: 3,
    relationName: "father_of",
    relationMembers: ["Anne", "Peter"],
    membersNs: [
      { id: "Anne", ns: "human" },
      { id: "Peter", ns: "human" },
    ],
  },
  {
    id: "ancestor-4",
    index: 4,
    relationName: "ancestor",
    relationMembers: ["John", "Isaac"],
    membersNs: [
      { id: "John", ns: "human" },
      { id: "Isaac", ns: "human" },
    ],
  },
  {
    id: "ancestor-5",
    index: 5,
    relationName: "ancestor",
    relationMembers: ["Isaac", "Anne"],
    membersNs: [
      { id: "Isaac", ns: "human" },
      { id: "Anne", ns: "human" },
    ],
  },
  {
    id: "ancestor-6",
    index: 6,
    relationName: "ancestor",
    relationMembers: ["Anne", "Peter"],
    membersNs: [
      { id: "Anne", ns: "human" },
      { id: "Peter", ns: "human" },
    ],
  },
  {
    id: "owns-7",
    index: 7,
    relationName: "owns",
    relationMembers: ["cat", "John"],
    membersNs: [
      { id: "cat", ns: "pets" },
      { id: "John", ns: "human" },
    ],
  },
  {
    id: "owns-8",
    index: 8,
    relationName: "owns",
    relationMembers: ["dog", "Isaac"],
    membersNs: [
      { id: "dog", ns: "pets" },
      { id: "Isaac", ns: "human" },
    ],
  },
  {
    id: "owns-9",
    index: 9,
    relationName: "owns",
    relationMembers: ["cow", "Anne"],
    membersNs: [
      { id: "cow", ns: "pets" },
      { id: "Anne", ns: "human" },
    ],
  },
];

export const eventEntitiesMock = {
  human: ["John", "Isaac", "Anne", "Peter"],
  pets: ["cat", "dog", "cow"],
};

export const entitiesTableRowsMock = [
  {
    index: 1,
    id: "John-1",
    entityId: "John",
    nameSpace: "human",
  },
  {
    index: 2,
    id: "Isaac-2",
    entityId: "Isaac",
    nameSpace: "human",
  },
  {
    index: 3,
    id: "Anne-3",
    entityId: "Anne",
    nameSpace: "human",
  },
  {
    index: 4,
    id: "Peter-4",
    entityId: "Peter",
    nameSpace: "human",
  },
  {
    index: 5,
    id: "cat-5",
    entityId: "cat",
    nameSpace: "pets",
  },
  {
    index: 6,
    id: "dog-6",
    entityId: "dog",
    nameSpace: "pets",
  },
  {
    index: 7,
    id: "cow-7",
    entityId: "cow",
    nameSpace: "pets",
  },
];
