import React from "react";
import { Box, CircularProgress as CircularProgressMUI } from "@mui/material";

export const CircularProgress = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <CircularProgressMUI />
  </Box>
);
