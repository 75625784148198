import React from 'react';
import IconButton from '@mui/material/IconButton';
import HighlightOffSharp from '@mui/icons-material/HighlightOffSharp';
import Tooltip from '@mui/material/Tooltip';

type CancelButtonProps = {
    onCancel: () => void;
    children: string;
  }

const CancelButton = ({ onCancel }: CancelButtonProps) => {
    return (
        <Tooltip title="Cancel" enterDelay={500}>
        <IconButton  onClick={onCancel} aria-label="cancel" sx={{color: "#00000061", padding: "8px"}}>
            <HighlightOffSharp />
        </IconButton>
        </Tooltip>
    );
};

export default CancelButton;
