import React, { useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Button } from "@mui/material";

type ResetButtonProps = {
  onReset: () => void;
};

const ResetButton = ({ onReset }: ResetButtonProps) => {
  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    onReset();
    setAnimate(true);
  };

  const handleAnimationEnd = () => {
    setAnimate(false);
  };
  return (
    <Button
      variant="outlined"
      startIcon={
        <RestartAltIcon
          sx={{ animation: animate ? "rotate 0.5s linear" : "none" }}
          onAnimationEnd={handleAnimationEnd}
        />
      }
      sx={{
        display: "flex",
        marginLeft: "1rem",
        padding: "0 10px",
        textTransform: "none",
      }}
      onClick={handleClick}
    >
      Reset
    </Button>
  );
};

export default ResetButton;
