import { Box, DialogContentText } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useSelector } from "react-redux";
import { IS_MULTIPLE_FILE_CHOICE } from "../../utils/constants";
import CSVFileInput from "../CSVFileInput";
import { RootState } from "../../store/store";
import { AddEntityID, FileData } from "../../store/types";
import { updateCsvFiles } from "./helpers";

const AddEntityFile = ({
  entityName,
  callbackEntityData,
  handleTextFieldError,
}: {
  entityName: string;
  callbackEntityData: (data: AddEntityID[]) => void;
  handleTextFieldError: () => void;
}) => {
  const allCsvFiles = useSelector((state: RootState) => state.builder.csvFiles);

  const handleEntitieCsvFiles = (csvFiles: FileData[]) => {
    handleTextFieldError();
    const indexOfExistingEntity = allCsvFiles.entities.findIndex(
      (entity: FileData) => entity.fileName === `entity_${entityName}`
    );
    if (indexOfExistingEntity !== -1 && !IS_MULTIPLE_FILE_CHOICE) {
      const currentCsvFiles = [...allCsvFiles.entities];
      currentCsvFiles.splice(indexOfExistingEntity, 1, ...csvFiles);
      const filesEntityData = updateCsvFiles({
        ...allCsvFiles,
        entities: currentCsvFiles,
      });
      callbackEntityData(filesEntityData);
    } else {
      const filesEntityData = updateCsvFiles({
        ...allCsvFiles,
        entities: [...allCsvFiles.entities, ...csvFiles],
      });
      callbackEntityData(filesEntityData);
    }
  };

  return (
    <Box position="relative">
      <DialogContentText sx={{ display: "flex", textAlign: "center" }}>
        {"By upload csv file"}
        <FileUploadOutlinedIcon />
      </DialogContentText>
      <CSVFileInput
        ns={entityName}
        fileName={entityName ? `entity_${entityName}` : ""}
        isMultipleFileChoice={IS_MULTIPLE_FILE_CHOICE}
        updateCsvFiles={handleEntitieCsvFiles}
        validateTextField={handleTextFieldError}
        arrayLength={allCsvFiles?.entities?.length ?? 0}
      />
    </Box>
  );
};
export default AddEntityFile;
