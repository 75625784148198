import React from 'react';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';

type ClearButtonProps = {
    onClear: () => void;
    children: string;
  }

const ClearButton = ({ onClear }: ClearButtonProps) => {
    return (
        <Tooltip title="Clear all" enterDelay={500}>
        <IconButton  onClick={onClear} aria-label="clear" sx={{color: "#00000061", padding: "8px"}}>
            <ClearIcon />
        </IconButton>
        </Tooltip>
    );
};

export default ClearButton;
