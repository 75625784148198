import React, { useState } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Relation } from "../../store/types";
import { EntityIDGroup } from "./EntityIDGroup";
import { useRelationUpdate } from "./helpers";

const RelationsEventsIds = ({
  index,
  relation,
  updateEventsData,
  handleRemoveField,
  isEventsDataEmpty,
  firstEntity,
  secondEntity
}: {
  index: number;
  relation: string;
  isEventsDataEmpty: boolean;
  firstEntity: string | null;
  secondEntity: string | null;
  updateEventsData: (updatedRelation: Partial<Relation>, index: number) => void;
  handleRemoveField: (index: number) => void;
}) => {
  const eventEntities = useSelector(
    (state: RootState) => state.builder.eventEntities
  );

  const nameSpaces = useSelector(
    (state: RootState) => state.builder.nameSpaces
  );
  // const [firstEntity, setFirstEntity] = useState<string | null>(null);
  const [firstEntityID, setFirstEntityID] = useState<string | null>(null);
  // const [secondEntity, setSecondEntity] = useState<string | null>(null);
  const [secondEntityID, setSecondEntityID] = useState<string | null>(null);

  useRelationUpdate(
    firstEntity,
    secondEntity,
    firstEntityID,
    secondEntityID,
    index,
    updateEventsData
  );

  // const handleFirstEntity = (entity: string | null) => {
  //   entity && setFirstEntity(entity);
  // };

  const handleFirstEntityID = (entityID: string | null) => {
    entityID && setFirstEntityID(entityID);
  };

  // const handleSecondEntity = (entity: string | null) => {
  //   entity && setSecondEntity(entity);
  // };

  const handleSecondEntityID = (entityID: string | null) => {
    entityID && setSecondEntityID(entityID);
  };

  return (
    <Box
      key={index}
      display={"flex"}
      margin={"1rem 0"}
      sx={{
        backgroundColor: index % 2 === 0 ? "#9893931a" : "#0000001f",
        boxShadow: "2px 3px 10px -7px #1976d2",
        padding: "0.5rem",
        borderRadius: "1rem 0",
      }}
    >
      <Typography
        variant="h6"
        component="h2"
        fontSize={14}
        width={"4rem"}
        alignSelf={"center"}
      >
        {`#${index + 1}`}
      </Typography>
      <EntityIDGroup
        id={`first-${index}`}
        entityLabel={"Choose first entity"}
        entitiesOptions={nameSpaces}
        entityIdLabel={"Choose first ID"}
        idSOptions={firstEntity ? eventEntities[firstEntity] : [""]}
        isClearState={isEventsDataEmpty}
        //handleEntity={handleFirstEntity}
        handleEntityID={handleFirstEntityID}
      />
      <EntityIDGroup
        id={`second-${index}`}
        entityLabel={"Choose second entity"}
        entitiesOptions={nameSpaces}
        entityIdLabel={"Choose second ID"}
        idSOptions={secondEntity ? eventEntities[secondEntity] : [""]}
        isClearState={isEventsDataEmpty}
        //handleEntity={handleSecondEntity}
        handleEntityID={handleSecondEntityID}
      />
      {index !== 0 && (
        <IconButton
          onClick={() => handleRemoveField(index)}
          sx={{ height: "40px", alignSelf: "flex-end" }}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default RelationsEventsIds;
