import React, { useEffect, useState } from "react";
import { groupBy } from "lodash";
import BasicPie from "../UIComponents/BasicPie";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { PieChartData } from "../../store/types";

const InputRelationsPieChart = () => {
  const [inputRelationsPie, setInputRelationsPie] = useState<PieChartData[]>([]);

  const inputRelationsTableRows = useSelector(
    (state: RootState) => state.builder.inputRelationsTableRows
  );
  const inputRelationsFilesTableRows = useSelector(
    (state: RootState) => state.builder.inputRelationsFilesTableRows
  );
  const isFileMode = useSelector(
    (state: RootState) => state.builder.isFileMode
  );

  useEffect(() => {
    const groupedByUniqueNameSpacesValue = groupBy(
      !isFileMode ? inputRelationsTableRows : inputRelationsFilesTableRows,
      (row) => row?.relationName
    );

    const data = Object.entries(groupedByUniqueNameSpacesValue).map(
      ([key, value], index) => ({
        id: index,
        value: value.length,
        label: key,
      })
    );
    setInputRelationsPie([{ data }]);
  }, [inputRelationsFilesTableRows, inputRelationsTableRows, isFileMode]);

  return <BasicPie series={inputRelationsPie} />;
}

export default InputRelationsPieChart;
