import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogTitle,
  IconButton,
  DialogContentText,
  Box,
  AlertColor,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  setCSVFilesAction,
  setEventsAction,
  setInputRelationsFilesTableRow,
  setInputRelationsTableRow,
} from "../../reducers/entityBuilderReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { FileData, Relation, RelationTableRow } from "../../store/types";
import {
  fullfillRelation,
  getDistinctEntitiesUpdateSentence,
  getUpdatedEvents,
} from "../../utils/helpers";
import RelationsEventsIds from "./ReleationsEventsIds";
import DialogFooter from "../UIComponents/DialogFooter";
import {
  AddRelationsButtonTitle,
  AddRelationsDescription,
  AddRelationsTitle,
  IS_MULTIPLE_FILE_CHOICE,
} from "../../utils/constants";
import { isEmpty } from "lodash";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SnackBarMessage from "../UIComponents/SnackBarMessage";
import AutoCompleteInput from "../UIComponents/AutoCompleteInput";
import CSVFileInput from "../CSVFileInput";
import DisableBlock from "../UIComponents/DisableBlock";
import {
  getUpdatedRelationsFilesTableRows,
  getUpdatedRelationsTableRows,
} from "./helpers";

const RelationsEvents = () => {
  const dispatch = useDispatch();
  const [firstEntity, setFirstEntity] = useState<string | null>(null);
  const [secondEntity, setSecondEntity] = useState<string | null>(null);
  const [relationsEventsData, setRelationsEventsData] = useState<
    Partial<Relation>[]
  >([{} as Relation]);
  const [relation, setRelation] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [isErrorTextField, setIsErrorTextField] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<{
    message: string;
    messageType: AlertColor | undefined;
  }>({ message: "", messageType: undefined });
  const currentEvents = useSelector((state: RootState) => state.builder.events);
  const nameSpaces = useSelector(
    (state: RootState) => state.builder.nameSpaces
  );
  const allCsvFiles = useSelector((state: RootState) => state.builder.csvFiles);
  const isFileMode = useSelector(
    (state: RootState) => state.builder.isFileMode
  );
  const inputRelationsTableRows = useSelector(
    (state: RootState) => state.builder.inputRelationsTableRows
  );
  const inputRelationsFilesTableRows = useSelector(
    (state: RootState) => state.builder.inputRelationsFilesTableRows
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRelationsCsvFiles = (csvFiles: FileData[]) => {
    if (IS_MULTIPLE_FILE_CHOICE) {
      const updatedCsvFiles = {
        ...allCsvFiles,
        relations: [...allCsvFiles.relations, ...csvFiles],
      };
      dispatch(setCSVFilesAction(updatedCsvFiles));
    } else {
      const currentCsvFiles = [...allCsvFiles.relations];
      const indexOfExistingEntity = allCsvFiles.relations.findIndex(
        (entity: FileData) => entity.fileName === `relation_${relation}`
      );
      if (indexOfExistingEntity !== -1) {
        currentCsvFiles.splice(indexOfExistingEntity, 1, ...csvFiles);
        dispatch(
          setCSVFilesAction({ ...allCsvFiles, relations: currentCsvFiles })
        );
      } else {
        dispatch(
          setCSVFilesAction({
            ...allCsvFiles,
            relations: [...allCsvFiles.relations, ...csvFiles],
          })
        );
      }
    }
  };

  const handleFirstEntity = (entity: string | null) => {
    entity && setFirstEntity(entity);
  };

  const handleSecondEntity = (entity: string | null) => {
    entity && setSecondEntity(entity);
  };

  const handleRelationChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!relation && e.target.value !== "") {
      setIsErrorTextField(false);
    }
    setRelation((e.target.value as string).trim());
  };

  const handleAddField = () => {
    setRelationsEventsData([...relationsEventsData, {} as Relation]);
  };

  const handleRemoveField = (index: number) => {
    const array = [...relationsEventsData];
    if (index > 0 && index < array.length) {
      array.splice(index, 1);
      setRelationsEventsData(array);
    }
  };

  const handleSubmit = () => {
    const cleanedRelationsEventsData = relationsEventsData.filter(
      (event) =>
        !isEmpty(event) && event?.firstEntityID?.id && event?.secondEntityID?.id
    );
    if (
      relation &&
      (cleanedRelationsEventsData.length > 0 ||
        !isEmpty(allCsvFiles?.relations))
    ) {
      const fullfilledEventsData = fullfillRelation(
        cleanedRelationsEventsData,
        relation
      );
      const normalizedUpdatedEvents = getUpdatedEvents(currentEvents, fullfilledEventsData);
      dispatch(setEventsAction(normalizedUpdatedEvents));
      setRelationsEventsData([{} as Relation]);
      !isFileMode
        ? dispatch(
            setInputRelationsTableRow(
              getUpdatedRelationsTableRows(
                relationsEventsData,
                inputRelationsTableRows,
                relation
              )
            )
          )
        : dispatch(
            setInputRelationsFilesTableRow(
              getUpdatedRelationsFilesTableRows(
                relationsEventsData,
                inputRelationsFilesTableRows,
                allCsvFiles,
                relation
              )
            )
          );
      setRelation("");
    } else if (!relation && !cleanedRelationsEventsData.length) {
      setDialogMessage({
        message: "Relation is required and at least one pair required.",
        messageType: "warning",
      });
    } else if (!relation) {
      setDialogMessage({
        message: "Relation is required.",
        messageType: "warning",
      });
    } else if (!cleanedRelationsEventsData.length) {
      setDialogMessage({
        message: "At least one pair required.",
        messageType: "warning",
      });
    }
  };

  const handleClear = () => {
    setRelationsEventsData([{} as Relation]);
    setRelation("");
    dispatch(setCSVFilesAction({ ...allCsvFiles, relations: [] }));
  };

  const updateEventsData = useCallback(
    (updatedRelation: Partial<Relation>, index: number) => {
      const tempEventsData: Partial<Relation>[] = [...relationsEventsData];
      tempEventsData[index] = updatedRelation;
      setRelationsEventsData(tempEventsData);
    },
    [relationsEventsData]
  );

  const handleAddAndClose = () => {
    const cleanedEventsData = relationsEventsData.filter(
      (event) =>
        !isEmpty(event) && event?.firstEntityID?.id && event?.secondEntityID?.id
    );
    handleSubmit();
    relation &&
      (cleanedEventsData.length > 0 || !isEmpty(allCsvFiles?.entities)) &&
      handleClose();
  };

  const handleTextFieldError = () => {
    setIsErrorTextField(!relation);
  };

  return (
    <Box>
      <Button
        id="add-relation-button"
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        disabled={isEmpty(nameSpaces)}
        sx={{
          width: {
            xs: "7rem",
            lg: "auto",
          },
          color: "white",
          border: "1px solid #ffffff70",
          "&:hover": { border: "1px solid white" },
          "&.Mui-disabled": {
            color: "grey",
            border: "1px solid grey",
          },
          fontSize: {
            xs: "0.4rem !important",
            sm: "0.5rem !important",
            md: "0.6rem !important",
            lg: "0.875rem !important",
          },
        }}
      >
        {AddRelationsButtonTitle}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xl"}
        disableScrollLock={true}
      >
        <DialogTitle>{AddRelationsTitle}</DialogTitle>
        <DialogContent>
          <TextField
            id={"relation-text-are"}
            error={isErrorTextField}
            margin="dense"
            name="rule"
            label={"Add new relation"}
            type="text"
            fullWidth
            variant="outlined"
            value={relation}
            onChange={handleRelationChange}
            sx={{
              minWidth: "12rem",
              margin: "0 0 1rem",
              paddingRight: "1rem",
              marginTop: "1rem",
            }}
          />

          <Grid container spacing={2}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={isFileMode ? 8 : 12}
              xl={isFileMode ? 8 : 12}
              sx={{
                borderTop: "1px solid grey",
                borderRight: "1px solid #a6a5a55f",
                borderRadius: "0 1rem 0rem",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Box>
                <DialogContentText>
                  {"Choose Relation Entities"}
                </DialogContentText>
              </Box>
            </Grid>
            {isFileMode && (
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                sx={{
                  paddingLeft: "1rem",
                  borderBottom: "1px solid grey",
                  borderRadius: "0 0 0 1rem",
                  paddingBottom: "1rem",
                }}
              >
                <Box>
                  <DialogContentText
                    sx={{ display: "flex", textAlign: "center" }}
                  >
                    {`${isFileMode ? "By" : "Or"} upload csv file`}
                    <FileUploadOutlinedIcon />
                  </DialogContentText>
                  <CSVFileInput
                    ns={relation}
                    fileName={relation ? `relation_${relation}` : ""}
                    isMultipleFileChoice={IS_MULTIPLE_FILE_CHOICE}
                    updateCsvFiles={handleRelationsCsvFiles}
                    validateTextField={handleTextFieldError}
                    arrayLength={allCsvFiles?.relations?.length ?? 0}
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          {!isFileMode && (
            <>
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  flexDirection: "row",
                  padding: "0 0 3rem",
                  marginTop: "1rem",
                }}
              >
                <AutoCompleteInput
                  id={`entity-select-1`}
                  width={"16rem"}
                  options={!isEmpty(nameSpaces) ? nameSpaces : [""]}
                  label={"First entity"}
                  isClearState={false}
                  getChosedOption={handleFirstEntity}
                />
                <AutoCompleteInput
                  id={`entity-select-2`}
                  width={"16rem"}
                  options={!isEmpty(nameSpaces) ? nameSpaces : [""]}
                  label={"Second entity"}
                  isClearState={false}
                  getChosedOption={handleSecondEntity}
                />
                {isFileMode ? (
                  <DisableBlock opacity={0.2} bgColor={"#607bb8"} />
                ) : null}
              </Box>
              <DialogContentText>{AddRelationsDescription}</DialogContentText>
              {relationsEventsData.map((_data, index) => (
                <Box position={"relative"} key={`${index}-relation`}>
                  <RelationsEventsIds
                    index={index}
                    relation={relation}
                    firstEntity={firstEntity}
                    secondEntity={secondEntity}
                    isEventsDataEmpty={isEmpty(relationsEventsData[index])}
                    updateEventsData={updateEventsData}
                    handleRemoveField={handleRemoveField}
                  />
                  {isFileMode ? (
                    <DisableBlock opacity={0.2} bgColor={"#607bb8"} />
                  ) : null}
                </Box>
              ))}
              <IconButton onClick={handleAddField} disabled={isFileMode}>
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          )}
        </DialogContent>
        <DialogFooter
          handleClear={handleClear}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          handleAddAndClose={handleAddAndClose}
        />
      </Dialog>
      <SnackBarMessage dialogMessage={dialogMessage} />
    </Box>
  );
};

export default RelationsEvents;
