import React from "react";
import { Box, Typography } from "@mui/material";

const AppFooter = () => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        bgcolor: "#524e4e",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        height: { xs: "3%", lg: "6%" },
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: {
            xs: "0.3rem",
            sm: "0.4rem",
            md: "0.6rem",
            lg: "0.8rem",
            xl: "1rem",
          },
        }}
      >
        © 2024 Log(ic)N data base solutions
      </Typography>
    </Box>
  );
};

export default AppFooter;
