import React from "react";
import { DialogActions, Button, Box } from "@mui/material";
import CancelButton from "./CancelButton";
import ClearButton from "./ClearButton";

const DialogFooter = ({
  handleClear,
  handleClose,
  handleSubmit,
  handleAddAndClose
}: {
  handleClear: () => void;
  handleClose: () => void;
  handleSubmit: () => void;
  handleAddAndClose: () => void;
}) => {
  return (
    <DialogActions>
      <Box sx={{ display: "flex", gap: "0.5rem", margin: "0 auto 1rem 0.8rem" }}>
        <Button variant="contained" onClick={handleSubmit}>
          Add
        </Button>
        <Button variant="outlined" onClick={handleAddAndClose}>
          Add & Close
        </Button>
      </Box>
      <Box sx={{ display: "flex", gap: "0.2rem", marginBottom: "0.2rem" }}>
        <ClearButton onClear={handleClear}>Clear</ClearButton>
        <CancelButton onCancel={handleClose}>Cancel</CancelButton>
      </Box>
    </DialogActions>
  );
};

export default DialogFooter;
