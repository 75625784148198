import { configureStore } from "@reduxjs/toolkit";
import createRootReducer from "../reducers";

const store = configureStore({
    reducer: createRootReducer,
  });
  
  export default store;

  export type RootState = ReturnType<typeof store.getState>;
  export type AppDispatch = typeof store.dispatch
