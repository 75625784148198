import React from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { AppDispatch, RootState } from "../store/store";
import {
  calculateRelations,
  calculateRelationsInZip,
  registerRelations,
} from "../actions/api/dataBaseAccess";
import {
  convertRelationsTableRowsToRelations,
  demoActions,
  filterByTargetMembers,
  getDistinctEntitiesUpdateSentence,
  zipFile,
} from "../utils/helpers";
import SplitButton from "./UIComponents/SplitButton";
import SnackBarMessage from "./UIComponents/SnackBarMessage";
import { resetRegisterMessageState } from "../reducers/registerReducer";
import { MenuOptions } from "../store/enums";
import { setEventsAction } from "../reducers/entityBuilderReducer";
import {
  ancestoryMockEvents,
  ancestoryMockRules,
  friendMockEvents,
  friendMockRules,
  genesMockEvents,
  genesMockRules,
  mockEvents,
  mockRules,
} from "../store/mocks";

const Calculation = ({ initMenuOption }: { initMenuOption: MenuOptions }) => {
  const dispatch = useDispatch<AppDispatch>();
  const allCsvFiles = useSelector((state: RootState) => state.builder.csvFiles);
  const { error, loading } = useSelector((state: RootState) => state.register);
  const currentEvents = useSelector((state: RootState) => state.builder.events);
  const isFileMode = useSelector(
    (state: RootState) => state.builder.isFileMode
  );
  const logicalRules = useSelector(
    (state: RootState) => state.builder.logicalRules
  );
  const nameSpaces = useSelector(
    (state: RootState) => state.builder.nameSpaces
  );
  const inputRelationsTableRows = useSelector(
    (state: RootState) => state.builder.inputRelationsTableRows
  );
  const entitiesIDs = useSelector(
    (state: RootState) => state.builder.entitiesIDs
  );

  const handleCalculate = async (choise: MenuOptions) => {
    if (choise === MenuOptions.CALCULATE) {
      const inputRelationsTableRowsWithUpdatedEntitiesIds =
        filterByTargetMembers(inputRelationsTableRows, entitiesIDs);
      const relations = convertRelationsTableRowsToRelations(
        inputRelationsTableRowsWithUpdatedEntitiesIds
      );
      const distinctEntitiesUpdateSentence =
        getDistinctEntitiesUpdateSentence(relations);
      const normalizedUpdatedEvents = [
        ...distinctEntitiesUpdateSentence,
        ...relations.map((relation) => relation.event),
      ];
      dispatch(setEventsAction(normalizedUpdatedEvents));
      const relationResponse = await dispatch(
        registerRelations({
          ns: nameSpaces,
          rules: logicalRules,
        })
      );
      dispatch(
        calculateRelations({
          schemaId: relationResponse?.payload?.id ?? "",
          events: normalizedUpdatedEvents,
        })
      );
    } else if (choise === MenuOptions.GET_ZIP_FILE) {
      const zipFileBlob = await zipFile(allCsvFiles);
      const relationResponse = await dispatch(
        registerRelations({
          ns: nameSpaces,
          rules: logicalRules,
        })
      );
      zipFileBlob &&
        dispatch(
          calculateRelationsInZip({
            schemaId: relationResponse?.payload?.id ?? "",
            zipFileBlob,
          })
        );
    } else if (choise === MenuOptions.RUN_DEMO) {
      demoActions(mockRules, mockEvents);
    } else if (choise === MenuOptions.ANCESTOR_DEMO) {
      demoActions(ancestoryMockRules, ancestoryMockEvents);
    } else if (choise === MenuOptions.FRIENDS_DEMO) {
      demoActions(friendMockRules, friendMockEvents);
    } else if (choise === MenuOptions.GENES_DEMO) {
      demoActions(genesMockRules, genesMockEvents);
    }
  };

  return (
    <Box>
      <SplitButton
        callback={handleCalculate}
        initOption={initMenuOption}
        menuOptions={
          isFileMode
            ? [MenuOptions.GET_ZIP_FILE]
            : [
                MenuOptions.CALCULATE,
                MenuOptions.RUN_DEMO,
                MenuOptions.ANCESTOR_DEMO,
                MenuOptions.FRIENDS_DEMO
              ]
        }
        disabledMenuOption={
          isFileMode &&
          (isEmpty(logicalRules) ||
            isEmpty(allCsvFiles?.entities) ||
            isEmpty(allCsvFiles?.relations))
            ? MenuOptions.GET_ZIP_FILE
            : !isFileMode && isEmpty(currentEvents)
            ? MenuOptions.CALCULATE
            : undefined
        }
      />
      <SnackBarMessage
        resetAction={() => dispatch(resetRegisterMessageState())}
        dialogMessage={{
          message: error ? error : loading ? "Processing..." : "",
          messageType: error ? "error" : loading ? "info" : undefined,
        }}
      />
    </Box>
  );
};

export default Calculation;
