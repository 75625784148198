import * as React from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

const AutoCompleteInput = ({
  id,
  options,
  label,
  width = 500,
  isClearState,
  getChosedOption,
}: {
  options: string[];
  id?: string;
  label: string;
  width?: number | string;
  dataLength?: number;
  isClearState?: boolean;
  getChosedOption: (value: string | null, index?: number) => void;
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    if (selectedOption && options && options.indexOf(selectedOption) === -1) {
      getChosedOption(options[0]);
      setSelectedOption(options[0]);
    }
  }, [getChosedOption, options, selectedOption]);

    useEffect(() => {
      if (isClearState && !isEmpty(options)) {
          getChosedOption(options[0]);
          setSelectedOption(options[0]);
      }
    }, [getChosedOption, isClearState, options]);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    getChosedOption(value);
    setSelectedOption(value);
  };

  return (
    <Stack spacing={2} sx={{ width, paddingRight: "1rem" }}>
      {options?.length && (
        <Autocomplete
          id={`${id ? id : `autocmp-${label}`}`}
          size="medium"
          sx={{ width: width }}
          options={options}
          getOptionLabel={(option) => option}
          defaultValue={options?.[0] ?? ""}
          value={selectedOption}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={label}
              placeholder="start type for search"
            />
          )}
        />
      )}
    </Stack>
  );
};

export default AutoCompleteInput;
