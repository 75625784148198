import { createSlice } from "@reduxjs/toolkit";
import { calculateRelations, calculateRelationsInZip } from "../actions/api/dataBaseAccess";
import { CalculateOutDataAccessState } from "../store/types";

  const initialState: CalculateOutDataAccessState ={ 
    data: null,
    loading: false,
    error: ""
};
const calculateRelationsSlice = createSlice({//
    name: 'calculateAction',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(calculateRelations.pending, (state) => {
                state.loading = true;
                state.error = "";
            })
            .addCase(calculateRelations.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(calculateRelations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Something went wrong";
            })
            .addCase(calculateRelationsInZip.pending, (state) => {
                state.loading = true;
                state.error = "";
            })
            .addCase(calculateRelationsInZip.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(calculateRelationsInZip.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Something went wrong";
            })
    },
    reducers: {resetCalculateRelationsState: () => initialState}
})

export const { resetCalculateRelationsState } = calculateRelationsSlice.actions;

export default calculateRelationsSlice.reducer;