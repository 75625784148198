import { useEffect } from "react";
import { CSVFiles, Relation, RelationTableRow } from "../../store/types";

export const useRelationUpdate = (
  firstEntityNs: string | null,
  secondEntityNs: string | null,
  firstEntityID: string | null,
  secondEntityID: string | null,
  index: number,
  updateEventsData: (updatedRelation: Partial<Relation>, index: number) => void
) => {
  useEffect(() => {
    if (firstEntityNs && secondEntityNs && firstEntityID && secondEntityID) {
      const updatedRelation = {
        firstEntityID: {
          ns: firstEntityNs,
          id: firstEntityID,
        },
        secondEntityID: {
          ns: secondEntityNs,
          id: secondEntityID,
        },
      };
      updateEventsData(updatedRelation, index);
    }
  }, [firstEntityID, secondEntityID, firstEntityNs, secondEntityNs, index]);
};

export const getUpdatedRelationsFilesTableRows = (
  relationsEventsData: Partial<Relation>[],
  inputRelationsFilesTableRows: RelationTableRow[],
  allCsvFiles: CSVFiles,
  relation: string
): RelationTableRow[] => {
  const startIndex = (inputRelationsFilesTableRows?.length ?? 0) + 1;
  const currentEntitiesFilesTableRows = relationsEventsData.map(
    (relationEvent, index) => ({
      id: `${relationEvent?.relation ?? relation}-${startIndex + index}`,
      index: startIndex + index,
      relationName: relationEvent?.relation ?? relation,
      relationMembers: [
        allCsvFiles?.relations?.[startIndex + index - 1]?.metaData
          ?.originalName ?? "file",
      ],
      membersNs: [{
        id: relationEvent?.firstEntityID?.id ?? "",
        ns: relationEvent?.firstEntityID?.ns ?? ""
      },{
        id: relationEvent?.secondEntityID?.id ?? "",
        ns: relationEvent?.secondEntityID?.ns ?? ""
      }]
    })
  );
  return [...inputRelationsFilesTableRows, ...currentEntitiesFilesTableRows];
};
export const getUpdatedRelationsTableRows = (
  relationsEventsData: Partial<Relation>[],
  inputRelationsTableRows: RelationTableRow[],
  relation: string
): RelationTableRow[] => {
  const startIndex = (inputRelationsTableRows?.length ?? 0) + 1;
  const currentEntitiesTableRows = relationsEventsData.map(
    (relationEvent, index) => ({
      id: `${relationEvent?.relation ?? relation}-${startIndex + index}`,
      index: startIndex + index,
      relationName: relationEvent?.relation ?? relation,
      relationMembers: [
        relationEvent?.firstEntityID?.id ?? "",
        relationEvent?.secondEntityID?.id ?? "",
      ],
          membersNs: [{
            id: relationEvent?.firstEntityID?.id ?? "",
            ns: relationEvent?.firstEntityID?.ns ?? ""
          },{
            id: relationEvent?.secondEntityID?.id ?? "",
            ns: relationEvent?.secondEntityID?.ns ?? ""
          }]
    })
  );
  return [...inputRelationsTableRows, ...currentEntitiesTableRows];
};
