import React, { useEffect, useState } from "react";
import BasicPie from "../UIComponents/BasicPie";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { PieChartData } from "../../store/types";
import { isEmpty } from "lodash";

const OutputRelationsPieChart = () => {
  const [relationsPie, setRelationsPie] = useState<PieChartData[]>([]);

  const { data } = useSelector((state: RootState) => state.calculate);
  const events = useSelector((state: RootState) => state.builder.events);

  useEffect(() => {
    const statistics = data?.relations_statistics;
    if (statistics) {
      const data = Object.entries(statistics).map(([key, value], index) => ({
        id: index,
        value: value.size,
        label: key,
      }));
      setRelationsPie([{ data }]);
    }
  }, [data]);

  useEffect(() => {
      isEmpty(events) && setRelationsPie([])
  }, [events]);

  return <BasicPie series={relationsPie} />;
};

export default OutputRelationsPieChart;
