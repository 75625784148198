import { createSlice } from "@reduxjs/toolkit";
import { registerRelations } from "../actions/api/dataBaseAccess";
import { RegisterDataAccessState } from "../store/types";

const initialState: RegisterDataAccessState = {
  data: null,
  loading: false,
  error: "",
};
const registerSlice = createSlice({
  name: "registerAction",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(registerRelations.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(registerRelations.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(registerRelations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Something went wrong";
      });
  },
  reducers: {resetRegisterMessageState: (state) => {
    state.error = "";
  }}
})

export const { resetRegisterMessageState } = registerSlice.actions;

export default registerSlice.reducer;
