import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { PieChartData } from "../../store/types";

export default function BasicPie({ series }: { series: PieChartData[] }) {
  return (
    <>
      {series.length > 0 && (
        <PieChart series={series} width={600} height={200} />
      )}
    </>
  );
}
