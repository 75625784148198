import React from 'react';
import EntitiesPieChart from '../AddEntity/EntitiesPieChart';
import InputRelationsPieChart from '../RelationEvents/InputRelationsPieChart';


const InputsPieChart = ({ tabIndex }: { tabIndex: number }) => {
  const getPiePerTab = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        return <EntitiesPieChart />;
      case 1:
        return <InputRelationsPieChart />;
      default:
        return <EntitiesPieChart />;  
    }
  };

  return getPiePerTab(tabIndex);
};

export default InputsPieChart;
