import React from "react";
import { DataGrid, GridLogicOperator } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { CircularProgress } from "./CircularProgress";
import {
  DataTableProps,
  EntityTableRow,
  RelationTableRow,
} from "../../store/types";
import { useEffect, useState } from "react";
import { GridFilterModel } from "@mui/x-data-grid";
import CustomFilter from "./CustomFilter";

const DataTable = ({
  tableName,
  rows,
  columns,
  loading,
  isSelectable,
  customFilterField,
  deleteRows,
}: DataTableProps) => {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    logicOperator: GridLogicOperator.Or,
    quickFilterLogicOperator: GridLogicOperator.Or,
    quickFilterExcludeHiddenColumns: false,
  });

  const handleSelectionChange = (selectionModel: any) => {
    deleteRows?.(selectionModel);
  };

  useEffect(() => {
    const uniqueRelationNames = (
      rows as Array<RelationTableRow | EntityTableRow>
    ).reduce((acc: string[], row: RelationTableRow | EntityTableRow) => {
      const fieldName: string =
        row[
          customFilterField as keyof RelationTableRow &
            keyof EntityTableRow["nameSpace"]
        ];
      if (fieldName && !acc.includes(fieldName)) {
        acc.push(fieldName);
      }
      return acc;
    }, [] as string[]);
    setFilterModel((prev) => ({
      ...prev,
      quickFilterValues: uniqueRelationNames,
    }));
  }, [customFilterField, rows]);

  const handleFilterModelChange = (model: GridFilterModel) => {
    setFilterModel(model);
  };

  return (
    <Box sx={{ height: 425 }}>
      {!rows || loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h6" gutterBottom>
            {tableName}
          </Typography>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection={isSelectable}
            rowSelection={isSelectable}
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
            slots={{
              toolbar: CustomFilter,
            }}
            slotProps={{
              toolbar: {
                filterModel,
                field: customFilterField,
                onFilterModelChange: handleFilterModelChange,
              },
            }}
            onRowSelectionModelChange={handleSelectionChange}
          />
        </>
      )}
    </Box>
  );
};

export default DataTable;
