import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddRelationsEvent,
  CalculateOutApiResponse,
  CSVFiles,
  EntityTableRow,
  EventEntities,
  RelationTableRow,
  RulesTableRow,
} from "../store/types";

export interface EntityBuilderState {
  entitiesTableRows: EntityTableRow[];
  entitiesFilesTableRows: EntityTableRow[];
  inputRelationsTableRows: RelationTableRow[];
  inputRelationsFilesTableRows: RelationTableRow[];
  rulesTableRows: RulesTableRow[];
  logicalRules: string[];
  events: string[];
  nameSpaces: string[];
  entitiesIDs: string[];
  eventEntities: EventEntities;
  relationsEventList: AddRelationsEvent[];
  currentEventEntity: string | null;
  calculatedRelations: CalculateOutApiResponse | null;
  isFileMode: boolean;
  csvFiles: CSVFiles;
}

const initialState: EntityBuilderState = {
  entitiesTableRows: [],
  entitiesFilesTableRows: [],
  inputRelationsTableRows: [],
  inputRelationsFilesTableRows: [],
  rulesTableRows: [],
  logicalRules: [],
  events: [],
  nameSpaces: [],
  entitiesIDs: [],
  eventEntities: {},
  relationsEventList: [],
  currentEventEntity: "",
  calculatedRelations: null,
  isFileMode: false,
  csvFiles: {
    entities: [],
    relations: [],
  },
};

const entityBuilderSlice = createSlice({
  name: "entityBuilder",
  initialState,
  reducers: {
    setEntityTableRow: (state, action: PayloadAction<EntityTableRow[]>) => {
      state.entitiesTableRows = action.payload;
    },
    setEntitiesFilesTableRow: (state,action: PayloadAction<EntityTableRow[]>
    ) => {
      state.entitiesFilesTableRows = action.payload;
    },
    setInputRelationsTableRow: (state, action: PayloadAction<RelationTableRow[]>) => {
      state.inputRelationsTableRows = action.payload;
    },
    setInputRelationsFilesTableRow: (state,action: PayloadAction<RelationTableRow[]>
    ) => {
      state.inputRelationsFilesTableRows = action.payload;
    },
    setRulesTableRows: (state, action: PayloadAction<RulesTableRow[]>) => {
      state.rulesTableRows = action.payload;
    },
    setLogicalRulesAction: (state, action: PayloadAction<string[]>) => {
      state.logicalRules = action.payload;
    },
    setEventsAction: (state, action: PayloadAction<string[]>) => {
      state.events = action.payload;
    },
    setNameSpacesAction: (state, action: PayloadAction<string[]>) => {
      state.nameSpaces = action.payload;
    },
    setEntitiesIDsAction: (state, action: PayloadAction<string[]>) => {
      state.entitiesIDs = action.payload;
    },
    setEventEntitiesAction: (state, action: PayloadAction<EventEntities>) => {
      state.eventEntities = action.payload;
    },
    setCalculatedRelationsAction: (
      state,
      action: PayloadAction<CalculateOutApiResponse>
    ) => {
      state.calculatedRelations = action.payload;
    },
    setCSVFilesAction: (state, action: PayloadAction<CSVFiles>) => {
      state.csvFiles = action.payload;
    },
    setIsFileModeAction: (state, action: PayloadAction<boolean>) => {
      state.isFileMode = action.payload;
    },
  },
});

export const {
  setEntityTableRow,
  setEntitiesFilesTableRow,
  setInputRelationsTableRow,
  setInputRelationsFilesTableRow,
  setRulesTableRows,
  setLogicalRulesAction,
  setEventsAction,
  setNameSpacesAction,
  setEntitiesIDsAction,
  setEventEntitiesAction,
  setCalculatedRelationsAction,
  setCSVFilesAction,
  setIsFileModeAction,
} = entityBuilderSlice.actions;

export default entityBuilderSlice.reducer;
