import { useDispatch, useSelector } from "react-redux";
import {
  setIsFileModeAction,
  setNameSpacesAction,
} from "../../reducers/entityBuilderReducer";
import { getNameSpaceValues } from "../../utils/helpers";
import { RootState } from "../../store/store";
import { FormControlLabel, Switch } from "@mui/material";
import { MenuOptions } from "../../store/enums";

const CalculatorModeSwitch = ({handleInitMenuOption}: {handleInitMenuOption: (arg: MenuOptions) => void}) => {
  const dispatch = useDispatch();
  const isFileMode = useSelector(
    (state: RootState) => state.builder.isFileMode
  );
  const entitiesTableRows = useSelector(
    (state: RootState) => state.builder.entitiesTableRows
  );

  const entitiesFilesTableRows = useSelector(
    (state: RootState) => state.builder.entitiesFilesTableRows
  );
  const handleCalculatorModeSwitch = () => {
    const updatedIsFileMode = !isFileMode;
    dispatch(setIsFileModeAction(updatedIsFileMode));
    const ns = getNameSpaceValues(
      !updatedIsFileMode ? entitiesTableRows : entitiesFilesTableRows
    );
    dispatch(setNameSpacesAction(ns));
    handleInitMenuOption(updatedIsFileMode ? MenuOptions.GET_ZIP_FILE : MenuOptions.CALCULATE);
  };
  return (
    <FormControlLabel
      control={<Switch checked={!isFileMode} />}
      label="Calculator Mode"
      sx={{ marginLeft: "1rem" }}
      onChange={handleCalculatorModeSwitch}
    />
  );
};
export default CalculatorModeSwitch;
