import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { RootState } from "../../store/store";
import { transformRelationsObject } from "../../utils/helpers";
import DataTable from "../UIComponents/DataTable";
import SnackBarMessage from "../UIComponents/SnackBarMessage";
import ResetButton from "../UIComponents/ResetButton";
import { setEventsAction } from "../../reducers/entityBuilderReducer";
import { isEmpty } from "lodash";
import { resetCalculateRelationsState } from "../../reducers/calculateRelationsReducer";

const columns: GridColDef[] = [
  { field: "index", headerName: "#", width: 70 },
  { field: "relationName", headerName: "Relations", flex: 1 },
  { field: "relationMembers", headerName: "Entities", flex: 1 },
];

const Registration = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state: RootState) => state.calculate
  );

  const handleReset = () => {
    dispatch(setEventsAction([]));
    dispatch(resetCalculateRelationsState());
  };

  return (
    <Box
      marginLeft={1}
      boxShadow="-14px -7px 20px -20px #00000029"
      padding="2rem 1rem 1rem"
    >
      <DataTable
        tableName="Output Relations"
        columns={columns}
        rows={!isEmpty(data) ? transformRelationsObject(data) : []}
        loading={loading}
        isSelectable={false}
        customFilterField="relationName"
      />
      <ResetButton onReset={handleReset}/>
      <SnackBarMessage
        dialogMessage={{
          message: error ? error : loading ? "Calculating..." : "",
          messageType: error ? "error" : loading ? "info" : undefined,
        }}
      />
    </Box>
  );
};

export default Registration;
