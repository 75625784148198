import { combineReducers } from "redux";
import calculateRelationsReducer from "./calculateRelationsReducer";
import registerReducer from "./registerReducer";
import entityBuilderReducer from "./entityBuilderReducer";

const reducers = combineReducers({
    calculate: calculateRelationsReducer,
    register: registerReducer,
    builder: entityBuilderReducer
});

export default reducers
export type ReducersState = ReturnType<typeof reducers>