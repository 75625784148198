import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Button } from "@mui/material";

type DeleteButtonProps = {
  onReset: () => void;
};

const DeleteButton = ({ onReset }: DeleteButtonProps) => {
  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    onReset();
    setAnimate(true);
  };

  const handleAnimationEnd = () => {
    setAnimate(false);
  };
  return (
    <Button
      variant="outlined"
      startIcon={
        <DeleteIcon
          sx={{ animation: animate ? "ring 0.2s linear" : "none" }}
          onAnimationEnd={handleAnimationEnd}
        />
      }
      sx={{
        position: "absolute",
        top: "25.5rem",
        display: "flex",
        marginLeft: "8rem",
        padding: "0 10px",
        textTransform: "none",
      }}
      onClick={handleClick}
    >
      Delete
    </Button>
  );
};

export default DeleteButton;
