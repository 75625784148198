import React, { useEffect, useState } from "react";
import { groupBy } from "lodash";
import BasicPie from "../UIComponents/BasicPie";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { PieChartData } from "../../store/types";

const EntitiesPieChart = () => {
  const [entitiesPie, setEntitiesPie] = useState<PieChartData[]>([]);

  const entitiesTableRows = useSelector(
    (state: RootState) => state.builder.entitiesTableRows
  );
  const entitiesFilesTableRows = useSelector(
    (state: RootState) => state.builder.entitiesFilesTableRows
  );
  const isFileMode = useSelector(
    (state: RootState) => state.builder.isFileMode
  );

  useEffect(() => {
    const groupedByUniqueNameSpacesValue = groupBy(
      isFileMode ? entitiesFilesTableRows : entitiesTableRows,
      (row) => row?.nameSpace
    );
    const data = Object.entries(groupedByUniqueNameSpacesValue).map(
      ([key, value], index) => ({
        id: index,
        value: value.length,
        label: key,
      })
    );
    setEntitiesPie([{ data }]);
  }, [entitiesFilesTableRows, entitiesTableRows, isFileMode]);

  return <BasicPie series={entitiesPie} />;
}

export default EntitiesPieChart;
