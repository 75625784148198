import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  MenuProps,
} from "@mui/material";
import {
  GridFilterModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

interface CustomFilterProps {
  filterModel: GridFilterModel;
  field: string;
  onFilterModelChange: (model: GridFilterModel) => void;
}

interface CustomMenuProps extends MenuProps {
    getContentAnchorEl: (() => Element) | null;
  }

const CustomFilter: React.FC<CustomFilterProps> = ({
  filterModel,
  field,
  onFilterModelChange,
}) => {
  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const value = event.target.value as string;
    const newFilterItems =
      value === "All"
        ? filterModel.items.filter((item) => item.field !== field)
        : [
            ...filterModel.items.filter((item) => item.field !== field),
            {
              id: value,
              field,
              operator: "equals",
              value: value,
            },
          ];
    onFilterModelChange({
      ...filterModel,
      items: newFilterItems,
    });
  };


  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton sx={{ fontSize: "0.7rem" }} />
      <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
        <Select
          labelId="quick-filter-select-label"
          id="quick-filter-select"
          value={
            filterModel.items.find((item) => item.field === field)?.value ||
            "All"
          }
          onChange={handleSelectChange}
          MenuProps={{
            disableScrollLock: true,
          }}
        >
          <MenuItem key="All" value="All" sx={{ fontSize: "0.9rem" }}>
            All
          </MenuItem>
          {filterModel.quickFilterValues?.map((value) => (
            <MenuItem key={value} value={value} sx={{ fontSize: "0.9rem" }}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </GridToolbarContainer>
  );
};

export default CustomFilter;
