import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import DataTable from "../UIComponents/DataTable";
import { Box } from "@mui/material";
import DeleteButton from "../UIComponents/DeleteButton";
import { useState } from "react";
import { EntityTableRow, FileData, LogicalRule } from "../../store/types";
import { isEmpty } from "lodash";
import {
  setCSVFilesAction,
  setEntitiesFilesTableRow,
  setEntitiesIDsAction,
  setEntityTableRow,
  setNameSpacesAction,
} from "../../reducers/entityBuilderReducer";
import { getNameSpaceValues, isTwoArraysEqual } from "../../utils/helpers";

const columns: GridColDef[] = [
  { field: "index", headerName: "#", width: 70 },
  { field: "entityId", headerName: "ID", flex: 1 },
  { field: "nameSpace", headerName: "Name space", flex: 1 },
];

const Entities = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [idsForDelete, setIdsForDelete] = useState<string[]>([]);
  const entitiesTableRows = useSelector(
    (state: RootState) => state.builder.entitiesTableRows
  );
  const entitiesFilesTableRows = useSelector(
    (state: RootState) => state.builder.entitiesFilesTableRows
  );
  const nameSpaces = useSelector(
    (state: RootState) => state.builder.nameSpaces
  );
  const logicalRules = useSelector(
    (state: RootState) => state.builder.logicalRules
  );
  const isFileMode = useSelector(
    (state: RootState) => state.builder.isFileMode
  );
  const allCsvFiles = useSelector((state: RootState) => state.builder.csvFiles);

  const getIdsForDelete = (idsForDelete: string[]) => {
    setIdsForDelete(idsForDelete);
  };

  const handleDeleteEntities = () => {
    const updatedEntitiesTableRows = (
      !isFileMode ? entitiesTableRows : entitiesFilesTableRows
    )?.reduce((acc: EntityTableRow[], row: EntityTableRow) => {
      if (!idsForDelete.includes(row?.id)) {
        return [...acc, row];
      }
      return acc;
    }, [] as EntityTableRow[]);
    !isFileMode
      ? dispatch(setEntityTableRow(updatedEntitiesTableRows))
      : dispatch(setEntitiesFilesTableRow(updatedEntitiesTableRows));
    const entitiesIds = updatedEntitiesTableRows.map(
      ({ entityId }) => entityId
    );
    dispatch(setEntitiesIDsAction(entitiesIds));
    const ns = getNameSpaceValues(updatedEntitiesTableRows);
    const isNameSpacesChanged = !isTwoArraysEqual(nameSpaces, ns);
    if (isNameSpacesChanged) {
      dispatch(setNameSpacesAction(ns));
    }
    if (isFileMode) {
      const entitiesCsvFiles = allCsvFiles?.entities?.reduce(
        (acc: FileData[], file: FileData) => {
          if (!idsForDelete.includes(file?.id)) {
            return [...acc, file];
          } else {
            URL.revokeObjectURL(file.dataURL);
          }
          return acc;
        },
        [] as FileData[]
      );
      dispatch(
        setCSVFilesAction({
          ...allCsvFiles,
          entities: entitiesCsvFiles,
        })
      );
    }
  };

  return (
    <Box
      boxShadow="25px -20px 20px -35px #0080ff52;"
      padding="1rem"
      position="relative"
    >
      <DataTable
        //tableName={`Entities ${isFileMode ? "Files" : ""}`}
        tableName=""
        columns={columns}
        rows={isFileMode ? entitiesFilesTableRows : entitiesTableRows}
        loading={false}
        isSelectable={true}
        customFilterField="nameSpace"
        deleteRows={getIdsForDelete}
      />
      {!isEmpty(idsForDelete) && (
        <DeleteButton onReset={handleDeleteEntities} />
      )}
    </Box>
  );
};

export default Entities;
