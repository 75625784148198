import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CalculateRelationsArgs, LogicalRule } from "../../store/types";
import { urlApiAccess } from "../../utils/constants";

//const urlApiAccess = process.env.REACT_APP_API_URL;

export const registerRelations = createAsyncThunk(
  "register",
  async ({ ns, rules }: LogicalRule) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: urlApiAccess
        ? `${urlApiAccess}/register-schema`
        : "/register-schema",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ns: ns,
        rules: rules,
      },
    };

    try {
      const response = await axios.request(config);
      console.log("register api", { datareg: response.data });
      return response.data;
    } catch (error) {
      console.error("Error during API access", error);
      throw error;
    }
  }
);

export const calculateRelations = createAsyncThunk(
  "calculateOut",
  async ({ schemaId, events }: CalculateRelationsArgs) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      timeout: 60000,
      url: urlApiAccess ? `${urlApiAccess}/calculate-out` : "/calculate-out",
      headers: {
        Accept: "application/zip",
        "Content-Type": "application/json, application/zip",
      },
      data: {
        "schema-id": schemaId,
        events: events,
      },
    };

    try {
      const response = await axios.request(config);
      console.log("calculateOut api", { data: response.data });
      return response.data;
    } catch (error) {
      console.error("Error during API access", error);
      throw error;
    }
  }
);

export const calculateRelationsInZip = createAsyncThunk(
  "calculateOutZip",
  async ({ schemaId, zipFileBlob }: any) => {
    const formData = new FormData();
    formData.append("file", zipFileBlob, "in.zip");
    formData.append("schema-id", schemaId);
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      timeout: 60000,
      url: urlApiAccess
        ? `${urlApiAccess}/zip-calculate-out/${schemaId}`
        : `/zip-calculate-out/${schemaId}`,
      headers: {
        Accept: "application/zip",
      },
      data: formData,
      responseType: "blob" as const,
    };
    try {
      const response = await axios.request(config);
      const blob = new Blob([response.data], { type: "application/zip" });
      //create a link and download it automatically
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "logicn-response-data.zip");
      document.body.appendChild(link);
      link.click();
      link.remove();
      console.log("calculateOutZip api", { data: response.data });
      return response.data;
    } catch (error) {
      console.error("Error during API access", error);
    }
  }
);
