import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  setLogicalRulesAction,
  setRulesTableRows,
} from "../../reducers/entityBuilderReducer";
import { RootState } from "../../store/store";
import DataTable from "../UIComponents/DataTable";
import DeleteButton from "../UIComponents/DeleteButton";
import { useState } from "react";
import { RulesTableRow } from "../../store/types";

const columns: GridColDef[] = [
  { field: "index", headerName: "#", width: 70 },
  { field: "logicalRule", headerName: "Logical Rules", flex: 1 },
];
const Rules = () => {
  const dispatch = useDispatch();
  const [idsForDelete, setIdsForDelete] = useState<string[]>([]);
  const rulesTableRows = useSelector(
    (state: RootState) => state.builder.rulesTableRows
  );

  const getIdsForDelete = (idsForDelete: string[]) => {
    setIdsForDelete(idsForDelete);
  };
  const handleDeleteRules = () => {
    const updatedRulesTableRows = rulesTableRows?.reduce(
      (acc: RulesTableRow[], row: RulesTableRow) => {
        if (!idsForDelete.includes(row?.id)) {
          return [...acc, row];
        }
        return acc;
      },
      [] as RulesTableRow[]
    );
    dispatch(setRulesTableRows(updatedRulesTableRows));
    const updatedRules = updatedRulesTableRows.map((data) => data.logicalRule);
    dispatch(setLogicalRulesAction(updatedRules));
  };

  return (
    <Box
      marginLeft={1}
      boxShadow="-14px -7px 20px -20px #00000029"
      padding="1rem"
      position="relative"
    >
      <DataTable
        tableName=""
        columns={columns}
        rows={!isEmpty(rulesTableRows) ? rulesTableRows : []}
        loading={false}
        isSelectable={true}
        customFilterField="logicalRule"
        deleteRows={getIdsForDelete}
      />
      {!isEmpty(idsForDelete) && <DeleteButton onReset={handleDeleteRules} />}
    </Box>
  );
};

export default Rules;
