import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogTitle,
  IconButton,
  DialogContentText,
  Box,
  AlertColor,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { setLogicalRulesAction, setRulesTableRows } from "../reducers/entityBuilderReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SnackBarMessage from "./UIComponents/SnackBarMessage";
import { isEmpty } from "lodash";
import DialogFooter from "./UIComponents/DialogFooter";
import { AddNewRulesDescription, AddNewRulesTitle, AddRulesButtonTitle } from "../utils/constants";
import { transformToRulesTableRows } from "../utils/helpers";

const AddRulesDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [rulesData, setRulesData] = useState<string[]>([""]);
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<{
    message: string;
    messageType: AlertColor | undefined;
  }>({ message: "", messageType: undefined });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const nameSpaces = useSelector(
    (state: RootState) => state.builder.nameSpaces
  );

  const allCsvFiles = useSelector((state: RootState) => state.builder.csvFiles);

  const isFileMode = useSelector(
    (state: RootState) => state.builder.isFileMode
  );

  const logicalRules = useSelector(
    (state: RootState) => state.builder.logicalRules
  );

  const events = useSelector((state: RootState) => state.builder.events);

  const handleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
      const newRulesData: string[] = [...rulesData];
      newRulesData[index] = e.target.value;
      setRulesData(newRulesData);
  };

  const handleAddField = () => {
    setRulesData([...rulesData, ""]);
  };
  const handleSubmit = () => {
    const cleanedRulesData = rulesData.filter((rules) => rules !== "");
    if (cleanedRulesData.length > 0) {
      const updatedRules = [...logicalRules, ...cleanedRulesData];
      handleClose();
      dispatch(setLogicalRulesAction(updatedRules));
      dispatch(setRulesTableRows(transformToRulesTableRows(updatedRules)))
    } else {
      setDialogMessage({
        message: "At least one rule required.",
        messageType: "warning"
      });
    }
  };

  const handleClear = () => {
    setRulesData([""]);
  };

  const handleRemoveField = (index: number) => {
    const array = [...rulesData];
    if (index > 0 && index < array.length) {
      array.splice(index, 1);
      setRulesData(array);
    }
  };

  const handleAddAndClose = () => {
    handleSubmit();
    rulesData.filter((rules) => rules !== "")?.length && handleClose();
  }

  return (
    <Box>
      <Button
        id="add-rules-button"
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        //disabled={isEmpty(nameSpaces)}
        disabled={(isFileMode && (isEmpty(allCsvFiles?.entities) || isEmpty(allCsvFiles?.relations))) || isEmpty(nameSpaces)}
        sx={{
          width: {
            xs: '7rem', 
            lg: 'auto'
          },
            color: "white",
            border: "1px solid #ffffff70",
            "&:hover": { border: "1px solid white" },
            "&.Mui-disabled": {
              color: "grey",
              border: "1px solid grey"
            },
            fontSize: {
              xs: '0.4rem !important', 
              sm: '0.5rem !important', 
              md: '0.6rem !important',
              lg: '0.875rem !important',
            },
          }}
      >
        {AddRulesButtonTitle}
      </Button>
      <Dialog open={open} onClose={handleClose} disableScrollLock={true}>
        <DialogTitle>{AddNewRulesTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {AddNewRulesDescription}
          </DialogContentText>
          {rulesData.map((ruleData, index) => (
            <Box key={`${index}-rule`} display={"flex"}>
              <TextField
                margin="dense"
                name="rule"
                label={`Rule #${index + 1}`}
                type="text"
                fullWidth
                variant="standard"
                value={ruleData}
                onChange={(e) => handleChange(index, e)}
              />
              {index !== 0 && (
                <IconButton
                  onClick={() => handleRemoveField(index)}
                  sx={{ height: "40px", alignSelf: "flex-end" }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              )}
            </Box>
          ))}
          <IconButton onClick={handleAddField}>
            <AddCircleOutlineIcon />
          </IconButton>
        </DialogContent>
        <DialogFooter
          handleClear={handleClear}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          handleAddAndClose={handleAddAndClose}
        />
      </Dialog>
      <SnackBarMessage dialogMessage={dialogMessage} />
    </Box>
  );
};

export default AddRulesDialog;
