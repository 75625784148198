import React, { useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import {
  calculateRelations,
  registerRelations,
} from "../actions/api/dataBaseAccess";
import store from "../store/store";
import {
  CSVFiles,
  CalculateOutApiResponse,
  EntityIdentifier,
  EntityTableRow,
  FileData,
  MetaData,
  Relation,
  RelationTableRow,
} from "../store/types";
import { mockEvents, mockRules } from "../store/mocks";
import {
  setEntitiesIDsAction,
  setEntityTableRow,
  setEventEntitiesAction,
  setEventsAction,
  setInputRelationsTableRow,
  setLogicalRulesAction,
  setNameSpacesAction,
  setRulesTableRows,
} from "../reducers/entityBuilderReducer";
import JSZip from "jszip";
import { MAX_FILE_SIZE } from "./constants";

const dispatch = store.dispatch;

export const transformToEntitiesTableRows = (
  obj: CalculateOutApiResponse | null
) => {
  let index = 1;
  const entities = obj?.entities;
  if (entities) {
    const entitiesArray = Object?.values(entities)?.map((entityObject) => {
      return Object?.entries(entityObject)?.map(([, entityIDObject]) => ({
        index: index++,
        id: entityIDObject.id,
        nameSpace: entityIDObject?.ns,
      }));
    });
    return entitiesArray.flatMap((item) => item);
  }
  return [];
};

export const transformRelationsObject = (
  data: CalculateOutApiResponse | null
) => {
  let index = 1;
  const originalEntities = data?.entities ?? [];
  const relations = data?.relations ?? [];
  const transformedEntities = Object.entries(originalEntities).reduce(
    (acc, [ns, entities]) => {
      Object.keys(entities).forEach((key) => {
        acc[key] = { id: key, ns };
      });
      return acc;
    },
    {} as Record<string, EntityIdentifier>
  );
  return (
    relations &&
    Object?.entries(relations)?.flatMap(
      ([relationName, relationPairs]) =>
        relationPairs &&
        relationPairs.map((pair) => ({
          id: `${pair}-${index}`,
          index: index++,
          relationName,
          relationMembers: pair,
          membersNs: [
            transformedEntities[pair[0]],
            transformedEntities[pair[1]],
          ],
        }))
    )
  );
};

export const transformToRulesTableRows = (rules: string[]) => {
  if (!isEmpty(rules)) {
    const rulesArray = rules.map((rule, index) => {
      return {
        index: ++index,
        id: `${rule}-${index}`,
        logicalRule: rule,
      };
    });
    return rulesArray;
  }
  return [];
};

export const useUpdateEffect = (callback: () => void, dependencies: any[]) => {
  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      callback();
    }
  }, dependencies);
};

export const getNameSpaceValues = (entities: EntityTableRow[]) => {
  return entities.reduce((acc: string[], { nameSpace }) => {
    if (!acc.includes(nameSpace)) {
      return [...acc, nameSpace];
    }
    return acc;
  }, []);
};

export const convertRelationsTableRowsToRelations = (
  inputRelationsTableRows: RelationTableRow[]
) => {
  return inputRelationsTableRows.map(
    ({ relationMembers, relationName, membersNs }) => ({
      relation: relationName,
      event:
        relationMembers[0] && relationMembers[1]
          ? `link ${relationName} ${relationMembers[0]} ${relationMembers[1]}`
          : "",
      firstEntityID: membersNs[0],
      secondEntityID: membersNs[1],
    })
  );
};

export const getDistinctEntitiesUpdateSentence = (
  relations: Partial<Relation>[]
) => {
  return relations.reduce(
    (
      acc: string[],
      {
        firstEntityID,
        secondEntityID,
      }: { firstEntityID?: EntityIdentifier; secondEntityID?: EntityIdentifier }
    ) => {
      if (firstEntityID && secondEntityID) {
        const { ns: firstNs, id: firstID } = firstEntityID;
        const { ns: secondNs, id: secondID } = secondEntityID;
        const firstUpdate = `update ${firstNs} ${firstID}`;
        const secondUpdate = `update ${secondNs} ${secondID}`;
        if (!acc.includes(firstUpdate)) {
          acc.push(firstUpdate);
        }
        if (!acc.includes(secondUpdate)) {
          acc.push(secondUpdate);
        }
      }
      return acc;
    },
    []
  );
};

export const getCleanedEntityData = (
  entityData: Omit<EntityTableRow, "index" | "id">[]
) => entityData.filter((entity) => entity.entityId !== "");

export const fullfillRelation = (
  eventsData: Partial<Relation>[],
  relation: string
) => {
  return eventsData.map((eventData) => {
    return {
      ...eventData,
      relation,
      event:
        eventData?.firstEntityID?.id && eventData?.secondEntityID?.id
          ? `link ${relation} ${eventData.firstEntityID.id} ${eventData.secondEntityID.id}`
          : "",
    };
  });
};
export const isTwoArraysEqual = (
  firstArray: string[],
  secondArray: string[]
) => {
  return (
    firstArray.length === secondArray.length &&
    firstArray.every((item) => secondArray.includes(item))
  );
};

export const registerAndCalculate = (
  ns: string[],
  rules: string[],
  events: string[]
) => {
  Promise.resolve(
    dispatch(
      registerRelations({
        ns: ns,
        rules: rules,
      })
    )
  ).then((data) => {
    dispatch(
      calculateRelations({
        schemaId: data?.payload?.id ?? "",
        events: events,
      })
    );
  });
};

export const blobURLToFile = async (blobURL: string, metaData: MetaData) => {
  if (blobURL) {
    const response = await fetch(blobURL);
    const blob = await response.blob();
    const file = new File([blob], metaData.name, {
      type: metaData.type,
      lastModified: metaData.lastModified,
    });
    return file;
  } else {
    return null;
  }
};

export const zipFile = async (allCsvFiles: CSVFiles) => {
  try {
    const zip = new JSZip();
    const fileGroups = Object.values(allCsvFiles);
    const allFiles: FileData[] = fileGroups.flat();
    const promises = allFiles.map(async (file) => {
      const reBuildedFile = await blobURLToFile(file.dataURL, file.metaData);
      //URL.revokeObjectURL(file.dataURL);
      return reBuildedFile?.arrayBuffer().then((fileData) => {
        zip.file(reBuildedFile.name, fileData);
      });
    });
    await Promise.all(promises);
    const zipFileBlob = await zip.generateAsync({ type: "blob" });
    return zipFileBlob;
  } catch (error) {
    console.log(error);
  }
};

export const isFilesSizesAcceptable = async (files: FileList | null) => {
  const maxSize = MAX_FILE_SIZE * 1024 * 1024;
  if (files && files.length) {
    let totalSize = 0;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      totalSize += file.size;
      if (file.size > maxSize) {
        alert(`One or more files exceed the ${MAX_FILE_SIZE}MB size limit.`);
        return false;
      }
    }
    if (totalSize > maxSize) {
      alert(`The total size of files exceeds the ${MAX_FILE_SIZE}MB limit.`);
      return false;
    }
  }
  return true;
};

export const getObjectURlsFiles = (
  files: FileList,
  name: string,
  ns: string,
  arrayLength: number
) => {
  const renamedFiles = Array.from(files).map(
    (file, index) =>
      new File([file], index === 0 ? name : `${name}_${index + 1}`, {
        type: file.type,
      })
  );
  const objectURlsFiles = Array.from(renamedFiles).map((file, index) => ({
    metaData: {
      lastModified: file.lastModified,
      name,
      size: file.size,
      type: file.type,
      webkitRelativePath: file.webkitRelativePath,
      originalName: files[index].name,
    },
    id: `${files[index].name}-${arrayLength + index + 1}`,
    dataURL: URL.createObjectURL(file),
    fileName: file.name,
    nameSpace: ns,
  }));
  return objectURlsFiles;
};

export const getUpdatedEvents = (
  currentEvents: string[],
  eventsData: Partial<Relation>[]
) => {
  const distinctEntities = getDistinctEntitiesUpdateSentence(eventsData);
  const events = eventsData.map(({ event }) => event);
  const updatedEvents = [
    ...currentEvents,
    ...distinctEntities,
    ...events,
  ].filter((value, index, self) => self.indexOf(value) === index);
  const normalizedUpdatedEvents: string[] = !updatedEvents
    ? [""]
    : (updatedEvents as string[]);
  return normalizedUpdatedEvents;
};

export const filterByTargetMembers = (
  objectsArray: RelationTableRow[],
  targetMembers: string[]
) => {
  return objectsArray.reduce((acc, object) => {
    const hasTargetMember = object.relationMembers.every((member) =>
      targetMembers.includes(member)
    );
    if (hasTargetMember) {
      acc.push(object);
    }
    return acc;
  }, [] as RelationTableRow[]);
};

const createEventEntitiesMock = (events: string[]) => {
  const updateEvents = events.filter((event) => event.startsWith("update"));
  const eventEntities = updateEvents.reduce(
    (acc: Record<string, string[]>, event) => {
      const splitedEvent = event.split(" ");
      const ns = splitedEvent?.[1] ?? "";
      const id = splitedEvent?.[2] ?? "";
      const arr = acc[ns as keyof typeof acc] || [];
      if (!arr.includes(id)) {
        return { ...acc, [ns]: [...arr, id] };
      }
      return acc;
    },
    {}
  );
  return eventEntities;
};

const createEntitiesTableRowsMock = (events: string[]) => {
  const updateEvents = events.filter((event) => event.startsWith("update"));
  const entitiesTableRowsMock = updateEvents.map((event, index) => {
    const [, nameSpace, entityId] = event.split(" ");
    return {
      index: index + 1,
      id: `${entityId}-${index + 1}`,
      entityId: entityId,
      nameSpace: nameSpace,
    };
  });
  return entitiesTableRowsMock;
};

const createInputRelationsTableRowsMock = (events: string[]) => {
  const linkEvents = events.filter((event) => event.startsWith("link"));
  const inputRelationsTableRowsMock = linkEvents.map((event, index) => {
    const [, relationName, entityId1, entityId2] = event.split(" ");
    const id1Namespace =
      events.find((e) => e.includes(entityId1))?.split(" ")[1] ?? "";
    const id2Namespace =
      events.find((e) => e.includes(entityId2))?.split(" ")[1] ?? "";
    return {
      id: `${relationName}-${index + 1}`,
      index: index + 1,
      relationName: relationName,
      relationMembers: [entityId1, entityId2],
      membersNs: [
        { id: entityId1, ns: id1Namespace },
        { id: entityId2, ns: id2Namespace },
      ],
    };
  });
  return inputRelationsTableRowsMock;
};

export const demoActions = (mockRules: string[], mockEvents: string[]) => {
  const entitiesTableRowsMock = createEntitiesTableRowsMock(mockEvents);
  const ns = getNameSpaceValues(entitiesTableRowsMock);
  const entitiesIds = entitiesTableRowsMock.map(({ entityId }) => entityId);
  const eventEntitiesMock = createEventEntitiesMock(mockEvents);
  dispatch(setNameSpacesAction(ns));
  dispatch(setEntitiesIDsAction(entitiesIds));
  dispatch(setEntityTableRow(entitiesTableRowsMock));
  dispatch(setEventEntitiesAction(eventEntitiesMock));
  dispatch(setEventsAction(mockEvents));

  const inputRelationsTableRowsMock =
    createInputRelationsTableRowsMock(mockEvents);
    dispatch(setInputRelationsTableRow(inputRelationsTableRowsMock));
  
  dispatch(setLogicalRulesAction(mockRules));
  dispatch(setRulesTableRows(transformToRulesTableRows(mockRules)));
  registerAndCalculate(ns, mockRules, mockEvents);
};
