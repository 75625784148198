import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { isEmpty } from "lodash";
import { FileData } from "../store/types";
import { getObjectURlsFiles, isFilesSizesAcceptable } from "../utils/helpers";

const CSVFileInput = ({
  ns,
  fileName,
  isMultipleFileChoice,
  arrayLength,
  updateCsvFiles,
  validateTextField,
}: {
  ns: string;
  fileName: string;
  isMultipleFileChoice: boolean;
  arrayLength: number;
  validateTextField: () => void;
  updateCsvFiles: (csvFiles: FileData[]) => void;
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileNames, setFileNames] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   if (!fileName) {
  //     setFileNames("");
  //     if(fileInputRef.current) fileInputRef.current.value = '';
  //   }
  // },[fileName])
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files: FileList | null = event.target.files;
    
    if (files && files.length) {
      const isSizeAcceptable = await isFilesSizesAcceptable(files);
      if (isSizeAcceptable) {
        const fileNamesList = Array.from(files)
          .map((file) => file.name)
          .join(", ");
        setFileNames(fileNamesList);
        const objectURlsFiles = getObjectURlsFiles(files, fileName, ns, arrayLength);
        updateCsvFiles(objectURlsFiles);
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    const files = event.dataTransfer.files;
    if (files && files.length) {
      const fileNamesList = Array.from(files)
        .map((file) => file.name)
        .join(", ");
      setFileNames(fileNamesList);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    setIsDragOver(false);
  };

  const handleClear = () => {
    setFileNames("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleClick = () => {
    validateTextField();
  };

  return (
    <Box
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      sx={{
        position: "relative",
        marginTop: "1rem",
        "&:hover": {
          "& .MuiTextField-root": {
            bgcolor: "#c8c8c8",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "black",
            },
          },
        },
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        label="Choose or Drop here"
        value={fileNames}
        sx={{
          bgcolor: isDragOver ? "#c8c8c8" : "#f1f1f1",
          label: { fontSize: "0.9rem", cursor: "pointer" },
          "& .MuiInputBase-input": {
            backgroundImage: `
              linear-gradient(45deg, rgba(200, 200, 200, 0.5) 25%, transparent 25%, transparent 50%, rgba(200, 200, 200, 0.5) 50%, rgba(200, 200, 200, 0.5) 75%, transparent 75%, transparent)
            `,
            backgroundSize: "10px 10px",
          },
        }}
        InputProps={{
          readOnly: true,
          endAdornment: fileNames && (
            <InputAdornment
              position="end"
              sx={{ cursor: "pointer", zIndex: 100 }}
            >
              <IconButton onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        multiple={isMultipleFileChoice}
        onChange={handleFileChange}
        //disabled={!fileName}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          cursor: "pointer",
        }}
      />
      {!fileName && (
        <div
          onClick={handleClick}
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            cursor: "pointer",
            opacity: 0,
          }}
        />
      )}
    </Box>
  );
};

export default CSVFileInput;
