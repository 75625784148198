const DisableBlock = ({opacity, bgColor}: {opacity: number, bgColor: string}) => {
    return <div
    style={{
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
      cursor: "initial",
      opacity,
      backgroundColor: bgColor,
    }}
  />;
}

export default DisableBlock