import {
  setEntityTableRow,
  setCSVFilesAction,
  setEventEntitiesAction,
  setEntitiesIDsAction,
  setEntitiesFilesTableRow,
} from "../../reducers/entityBuilderReducer";
import store from "../../store/store";
import { AddEntityID, CSVFiles, EntityTableRow, EventEntities } from "../../store/types";

const dispatch = store.dispatch;

export const updateCsvFiles = (updatedCsvFiles: CSVFiles) => {
  dispatch(setCSVFilesAction(updatedCsvFiles));
  const filesEntityData = updatedCsvFiles.entities.map((file) => ({
    entityId: file.metaData.originalName,
    nameSpace: file.nameSpace,
  }));
  return filesEntityData;
};

export const processSubmitCsvFiles = (entityData: AddEntityID[]) => {
  const dataWithIndexes = entityData.map((entity, index) => ({
    ...entity,
    index: index + 1,
    id: `${entity.entityId}-${index + 1}`,
    nameSpace: entity.nameSpace,
  }));
  dispatch(setEntitiesFilesTableRow(dataWithIndexes));
};

export const processSubmitEntities = (
  entityData: AddEntityID[],
  cleanedEntityData: AddEntityID[],
  entitiesTableRows: EntityTableRow[],
  entityName: string,
  eventEntities: EventEntities
) => {
  const startIndex = entitiesTableRows.length;
  const dataWithIndexes = cleanedEntityData.map((entity, index) => ({
    ...entity,
    index: startIndex + index + 1,
    id: `${entity.entityId}-${startIndex + index + 1}`,
    nameSpace: entityName,
  }));
  const updatedEntities = [...entitiesTableRows, ...dataWithIndexes];
  const entitiesIds = updatedEntities.map(({ entityId }) => entityId);
  const updatedEventEntities = {
    ...eventEntities,
    [entityData[0].nameSpace]: cleanedEntityData.map(
      ({ entityId }) => entityId
    ),
  };
  dispatch(setEventEntitiesAction(updatedEventEntities));
  dispatch(setEntitiesIDsAction(entitiesIds));
  dispatch(setEntityTableRow(updatedEntities));
};
