import React from "react";
import AutoCompleteInput from "../UIComponents/AutoCompleteInput";
import { Box } from "@mui/material";

export const EntityIDGroup = ({
  id,
  entitiesOptions,
  entityLabel,
  entityIdLabel,
  idSOptions,
  isClearState,
  //handleEntity,
  handleEntityID,
}: {
  id: string;
  entitiesOptions: string[];
  entityLabel: string;
  entityIdLabel: string;
  idSOptions: string[];
  isClearState: boolean;
  //handleEntity: (entity: string | null) => void;
  handleEntityID: (entityID: string | null) => void;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {/* <AutoCompleteInput
        id={`entity-${id}`}
        width={"12rem"}
        options={entitiesOptions}
        label={entityLabel}
        isClearState={isClearState}
        getChosedOption={handleEntity}
      /> */}
      <AutoCompleteInput
        id={`entityId-${id}`}
        width={"12rem"}
        options={idSOptions}
        label={entityIdLabel}
        isClearState={isClearState}
        getChosedOption={handleEntityID}
      />
    </Box>
  );
};
