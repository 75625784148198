import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Unstable_Grid2";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import "./Logicn.css";
import Entities from "./components/Tables/Entities";
import Relations from "./components/Tables/Relations";
import AddEntityForm from "./components/AddEntity/AddEntityForm";
import AddRulesDialog from "./components/AddRules";
import Calculation from "./components/Calculation";
import RelationsEvents from "./components/RelationEvents/RelationsEvents";
import AppFooter from "./components/AppFooter";
import OutputRelationsPieChart from "./components/RelationEvents/OutputRelationsPieChart";
import { demoActions, useUpdateEffect } from "./utils/helpers";
import InputRelations from "./components/Tables/InputRelations";
import CalculatorModeSwitch from "./components/UIComponents/CalculatorModeSwitch";
import { MenuOptions } from "./store/enums";
import InputsPieChart from "./components/UIComponents/InputsPieChart";
import Rules from "./components/Tables/Rules";
import { mockEvents, mockRules } from "./store/mocks";

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`logicn-tabpanel-${index}`}
      aria-labelledby={`logicn-tab-${index}`}
      {...other}
    >
    {value === index && children}
    </div>
  );
}
function Logicn() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [initMenuOption, setInitMenuOption] = useState(MenuOptions.RUN_DEMO);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleInitMenuOption = (menuOption: MenuOptions) => {
    setInitMenuOption(menuOption);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `logicn-tab-${index}`,
      "aria-controls": `logicn-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    demoActions(mockRules, mockEvents);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        paddingTop: { xs: "8%", sm: "10%", lg: "12%" },
        marginBottom: "3%",
      }}
    >
      <AppBar
        position="static"
        sx={{
          display: { xs: "block", lg: "none" },
          position: "fixed",
          top: 0,
          bgcolor: "#282c34",
          zIndex: "100",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Log(ic)N
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            backgroundColor: "#282c34",
          },
        }}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ListItemText>
            <Typography variant="h6" noWrap color={"white"}>
              Log(ic)N
            </Typography>
          </ListItemText>
          <ListItem sx={{ marginLeft: 0 }}>
            <AddEntityForm />
          </ListItem>
          <ListItem>
            <RelationsEvents />
          </ListItem>
          <ListItem>
            <AddRulesDialog />
          </ListItem>
          <ListItem>
            <Calculation initMenuOption={initMenuOption}/>
          </ListItem>
        </List>
      </Drawer>
      <Box
        sx={{
          display: { xs: "none", lg: "flex" },
          position: "fixed",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          marginBottom: "1.5rem",
          backgroundColor: "#282c34",
          height: "20%",
          width: "100%",
          zIndex: "100",
          top: 0,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "1rem !important",
              sm: "1rem !important",
              md: "1rem !important",
              lg: "1.3rem !important",
              xl: "2rem !important",
            },
            marginTop: "1.5rem",
          }}
        >
          {"Log(ic)N"}
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 100,
            lineHeight: 1.5,
            letterSpacing: " 0.3em",
            fontSize: {
              xs: "1rem !important",
              sm: "1rem !important",
              md: "1rem !important",
              lg: "0.6rem !important",
              xl: "1rem !important",
            },
          }}
        >
          Data Base Solutions
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 3,
            marginTop: 3,
            gap: 3,
          }}
        >
          <AddEntityForm />
          <RelationsEvents />
          <AddRulesDialog />
          <Calculation initMenuOption={initMenuOption}/>
        </Box>
      </Box>
      <Grid container spacing={0}>
        <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <CalculatorModeSwitch handleInitMenuOption={handleInitMenuOption}/>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            sx={{
              marginLeft: "1rem",
           }}
            aria-label="logicn tabs"
          >
            <Tab label="Entities" {...a11yProps(0)} sx={{padding: "0 7rem"}}/>
            <Tab label="Input Relations" {...a11yProps(1)} sx={{padding: "0 5rem"}}/>
            <Tab label="Rules" {...a11yProps(2)} sx={{padding: "0 8rem"}}/>
          </Tabs>
          <CustomTabPanel value={tabIndex} index={0}>
            <Entities />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            <InputRelations />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={2}>
            <Rules />
          </CustomTabPanel>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
          <Relations />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <InputsPieChart tabIndex={tabIndex} />
        </Grid>
        <Grid
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <OutputRelationsPieChart />
        </Grid>
      </Grid>
      <AppFooter />
    </Box>
  );
}

export default Logicn;
