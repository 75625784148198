export enum DataAccessState {
    IDLE = "idle",
    LOADING = "loading",
    SUCCESS = "success",
    ERROR = "error",
}

export enum MenuOptions {
    CALCULATE = "Calculate",
    GET_ZIP_FILE = "Get Zip File",
    RUN_DEMO = "Run Demo",
    ANCESTOR_DEMO = "Ancestor Demo",
    FRIENDS_DEMO = "Friends Demo",
    GENES_DEMO = "Genes Demo",
}