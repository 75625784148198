import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  DialogTitle,
  DialogContentText,
  Box,
  AlertColor,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  setNameSpacesAction,
} from "../../reducers/entityBuilderReducer";
import { RootState } from "../../store/store";
import { AddEntityID } from "../../store/types";
import SnackBarMessage from "../UIComponents/SnackBarMessage";
import { getCleanedEntityData } from "../../utils/helpers";
import DialogFooter from "../UIComponents/DialogFooter";
import {
  AddEntityButtonTitle,
  AddNewEntityIdDescription,
  AddNewEntityTitle,
  EntityIdTitle
} from "../../utils/constants";
import {
  processSubmitCsvFiles,
  processSubmitEntities,
} from "./helpers";
import AddEntityFile from "./AddEntityFile";
import AddEntitiesIDs from "./AddEntitiesIDs";

const AddEntityDialog = () => {
  const dispatch = useDispatch();
  const [entityFormData, setEntityFormData] = useState([
    { entityId: "", nameSpace: "" },
  ]);
  const [entityName, setEntitName] = useState("");
  const [open, setOpen] = useState(false);
  const [isErrorTextField, setIsErrorTextField] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<{
    message: string;
    messageType: AlertColor | undefined;
  }>({ message: "", messageType: undefined });

  const nameSpaces = useSelector(
    (state: RootState) => state.builder.nameSpaces
  );
  const entitiesTableRows = useSelector(
    (state: RootState) => state.builder.entitiesTableRows
  );
  const eventEntities = useSelector(
    (state: RootState) => state.builder.eventEntities
  );
  const isFileMode = useSelector(
    (state: RootState) => state.builder.isFileMode
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const callbackEntityData = (data: AddEntityID[]) => {
    setEntityFormData(data);
  };

  const handleSetEntityName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!entityName && e.target.value !== "") {
      setIsErrorTextField(false);
    }
    setEntitName((e.target.value as string).trim());
  };

  const handleSubmit = () => {
    const cleanedEntityData = getCleanedEntityData(entityFormData);
    if (cleanedEntityData.length > 0 && entityName) {
      isFileMode
        ? processSubmitCsvFiles(cleanedEntityData)
        : processSubmitEntities(
            entityFormData,
            cleanedEntityData,
            entitiesTableRows,
            entityName,
            eventEntities
          );
      if (!nameSpaces.includes(entityName)) {
        dispatch(setNameSpacesAction([...nameSpaces, entityName]));
      }
      setDialogMessage({
        ...{ message: "Added new entities", messageType: "success" },
      });
      setEntitName("");
      setEntityFormData([{ entityId: "", nameSpace: "" }]);
    } else {
      setDialogMessage({
        ...{
          message: `${!entityName ? "Name Space required" : ""}${
            !entityName
              ? !cleanedEntityData.length
                ? ", and at least one id required."
                : "."
              : "At least one id required"
          } `,
          messageType: "warning",
        },
      });
    }
  };

  const handleClear = () => {
    setEntitName("");
    setEntityFormData([{ entityId: "", nameSpace: "" }]);
  };

  const handleAddAndClose = () => {
    handleSubmit();
    entityName && getCleanedEntityData(entityFormData)?.length && handleClose();
  };

  const handleTextFieldError = () => {
    setIsErrorTextField(!entityName);
  };

  return (
    <Box>
      <Button
        id="add-entity-button"
        variant="outlined"
        onClick={handleOpen}
        sx={{
          width: {
            xs: "7rem",
            lg: "auto",
          },
          color: "white",
          border: "1px solid #ffffff70",
          "&:hover": { border: "1px solid white" },
          fontSize: {
            xs: "0.4rem !important",
            sm: "0.5rem !important",
            md: "0.6rem !important",
            lg: "0.875rem !important",
          },
        }}
      >
        {AddEntityButtonTitle}
      </Button>
      <Dialog open={open} onClose={handleClose} disableScrollLock={true}>
        <DialogTitle>{AddNewEntityTitle}</DialogTitle>
        <DialogContent>
          <TextField
            id="text-area-name-space"
            error={isErrorTextField}
            autoFocus
            autoComplete="off"
            margin="dense"
            name="nameSpace"
            label="Name Space"
            type="text"
            fullWidth
            variant="outlined"
            value={entityName}
            onChange={handleSetEntityName}
          />
        </DialogContent>
        <DialogTitle paddingBottom={0}>{EntityIdTitle}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              sx={{
                borderTop: "1px solid grey",
                borderRight: "1px solid #a6a5a55f",
                borderRadius: "0 1rem 0rem",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Box>
                <DialogContentText>
                  {AddNewEntityIdDescription}
                </DialogContentText>
              </Box>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              sx={{
                paddingLeft: "1rem",
                borderBottom: "1px solid grey",
                borderRadius: "0 0 0 1rem",
                paddingBottom: "1rem",
              }}
            >
              {isFileMode && (
                <AddEntityFile
                  entityName={entityName}
                  callbackEntityData={callbackEntityData}
                  handleTextFieldError={handleTextFieldError}
                />
              )}
            </Grid>
          </Grid>
          {!isFileMode && (
            <AddEntitiesIDs
              entityName={entityName}
              entityData={entityFormData}
              callbackEntityData={callbackEntityData}
            />
          )}
        </DialogContent>
        <DialogFooter
          handleClear={handleClear}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          handleAddAndClose={handleAddAndClose}
        />
      </Dialog>
      <SnackBarMessage dialogMessage={dialogMessage} />
    </Box>
  );
};

export default AddEntityDialog;
