import { MenuOptions } from "../store/enums";

export const calculateButtonOptions = [MenuOptions.CALCULATE, MenuOptions.GET_ZIP_FILE, MenuOptions.RUN_DEMO];
export const AddRelationsButtonTitle = "Add Relation";
export const AddRelationsTitle = "Add Relations";
export const AddRelationsDescription = "Add relation pairs in the quantity you need. At least a one pair required.";

export const AddEntityButtonTitle = "Add Entity";
export const AddNewEntityTitle = "Add New Entity";
export const EntityIdTitle = "Entity ID";
export const AddNewEntityIdDescription = "Add entity identificator in the quantity you need. At least one id required."

export const AddRulesButtonTitle = "Add Rules";
export const AddNewRulesTitle = "Add Logical Rules";
export const AddNewRulesDescription = "Add logical rules in the quantity you need. At least one rule required."

export const urlApiAccess = "https://videocommentsrouter.onrender.com/logicn"

export const MAX_FILE_SIZE = 1//MB

export const IS_MULTIPLE_FILE_CHOICE = false