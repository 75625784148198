import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Box } from "@mui/material";
import { MenuOptions } from "../../store/enums";

export default function SplitButton({
  callback,
  menuOptions,
  initOption,
  disabledMenuOption,
}: {
  callback: (choise: MenuOptions) => void;
  menuOptions: string[];
  initOption: MenuOptions;
  disabledMenuOption?: MenuOptions;
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState(initOption);


  useEffect(() => {
    setSelectedOption(initOption);
  }, [initOption]);

  const handleClick = () => {
    callback(selectedOption);
  };

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: string
  ) => {
    setSelectedOption(option as MenuOptions);
    setOpen(false);
    callback(option as MenuOptions);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ display: "inline", 
    width: "7rem", 
    }}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{
          width: {
            xs: "7rem",
            lg: "auto",
          },
         
        }}
      >
        <Button
          onClick={handleClick}
          disabled={disabledMenuOption === selectedOption}
          sx={{
            height: "auto",
            width: "10.5rem",
            "&.Mui-disabled": {
              color: "grey",
              backgroundColor: "#1976d23b",
              border: "1px solid grey",
            },
            fontSize: {
              xs: "0.4rem !important",
              sm: "0.5rem !important",
              md: "0.6rem !important",
              lg: "0.875rem !important",
            },
          }}
        >
          {selectedOption}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label={menuOptions[0]}
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            padding: 0,
          }}
        >
          <ArrowDropDownIcon sx={{ width: {
              xs: "1rem !important",
              sm: "1rem !important",
              md: "inherit !important",
            } }}/>
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {menuOptions.map((option) => (
                    <MenuItem sx={{
                      fontSize: {
                        xs: "0.4rem !important",
                        sm: "0.5rem !important",
                        md: "0.7rem !important",
                        lg: "0.875rem !important",
                      }}}
                      key={option}
                      disabled={option === disabledMenuOption}
                      selected={option === selectedOption}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
