import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import DataTable from "../UIComponents/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  setCSVFilesAction,
  setInputRelationsFilesTableRow,
  setInputRelationsTableRow,
} from "../../reducers/entityBuilderReducer";
import { AppDispatch, RootState } from "../../store/store";
import { useState } from "react";
import {
  FileData,
  RelationTableRow,
} from "../../store/types";
import DeleteButton from "../UIComponents/DeleteButton";

const columns: GridColDef[] = [
  { field: "index", headerName: "#", width: 70 },
  { field: "relationName", headerName: "Relations", flex: 1 },
  { field: "relationMembers", headerName: "Entities", flex: 1 },
];

const InputRelations = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [idsForDelete, setIdsForDelete] = useState<string[]>([]);
  const inputRelationsTableRows = useSelector(
    (state: RootState) => state.builder.inputRelationsTableRows
  );
  const inputRelationsFilesTableRows = useSelector(
    (state: RootState) => state.builder.inputRelationsFilesTableRows
  );
  const isFileMode = useSelector(
    (state: RootState) => state.builder.isFileMode
  );
  const allCsvFiles = useSelector((state: RootState) => state.builder.csvFiles);

  const getIdsForDelete = (idsForDelete: string[]) => {
    setIdsForDelete(idsForDelete);
  };
  const getUpdatedEntitiesTableRows = (
    inputRelationsTableRows: RelationTableRow[]
  ) => {
    return inputRelationsTableRows?.reduce(
      (acc: RelationTableRow[], row: RelationTableRow) => {
        if (!idsForDelete.includes(row?.id)) {
          return [...acc, row];
        }
        return acc;
      },
      [] as RelationTableRow[]
    );
  };
  const handleDeleteRelations = () => {
    if (isFileMode) {
      const relationsCsvFiles = allCsvFiles?.relations?.reduce(
        (acc: FileData[], file: FileData) => {
          if (!idsForDelete.includes(file?.id)) {
            return [...acc, file];
          } else {
            URL.revokeObjectURL(file.dataURL);
          }
          return acc;
        },
        [] as FileData[]
      );
      dispatch(
        setCSVFilesAction({
          ...allCsvFiles,
          relations: relationsCsvFiles,
        })
      );
      const updatedInputRelationsFilesTableRowss = getUpdatedEntitiesTableRows(
        inputRelationsFilesTableRows
      );
      dispatch(
        setInputRelationsFilesTableRow(updatedInputRelationsFilesTableRowss)
      );
    } else {
      const updatedInputRelationsTableRows = getUpdatedEntitiesTableRows(
        inputRelationsTableRows
      );
      dispatch(setInputRelationsTableRow(updatedInputRelationsTableRows));
    }
  };

  return (
    <Box
      boxShadow="25px -20px 20px -35px #0080ff52;"
      padding="1rem"
      position={"relative"}
    >
      <DataTable
        tableName=""
        columns={columns}
        rows={
          isFileMode ? inputRelationsFilesTableRows : inputRelationsTableRows
        }
        loading={false}
        isSelectable={true}
        customFilterField="relationName"
        deleteRows={getIdsForDelete}
      />
      {!isEmpty(idsForDelete) && (
        <DeleteButton onReset={handleDeleteRelations} />
      )}
    </Box>
  );
};

export default InputRelations;
